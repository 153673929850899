<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="700px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.label"
                    label="Libelle"
                    :rules="[(v) => !!v || 'Libelle obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="3">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.ref_ext"
                    label="Reference"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="12">
                  <v-textarea
                    outlined
                    dense
                    v-model="editedItem.description"
                    label="Description"
                    :readonly="!modify"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <selecttree
                    :items="list"
                    v-model="parent"
                    :value="parent"
                    :label="'Parent'"
                    :item_disabled="'locked'"
                    :clearable="editedItem.fk_parent != 0"
                    :deletable_chips="false"
                    :readonly="!modify"
                    :select_type="'leaf'"
                    :selectable="false"
                    :closeoncontentclick="true"
                    @change="parent_change"
                  >
                  </selecttree>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="
                    parent.length == 0 && cat_type == 4 && $store.state.isadmin
                  "
                >
                  <selecttree
                    :items="structures"
                    v-model="struct_id"
                    :value="struct_id"
                    :label="'Structure'"
                    :item_disabled="'locked'"
                    :oblig="true"
                    :clearable="false"
                    :deletable_chips="false"
                    :readonly="!modify"
                    :select_type="'leaf'"
                    :selectable="false"
                    :closeoncontentclick="true"
                    @change="struct_change"
                  >
                  </selecttree>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="color"
                    hide-details
                    class="ma-0 pa-0"
                    solo
                    label="Couleur"
                    clearable
                    :readonly="!modify"
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="color"
                              flat
                              :disabled="!modify"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_CATEGORIE from "../graphql/Categorie/CREATE_CATEGORIE.gql";
import UPDATE_CATEGORIE from "../graphql/Categorie/UPDATE_CATEGORIE.gql";

export default {
  name: "categorie",
  components: {
    selecttree: () => import("../components/SelectTree.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    type_cat: Number,
    list: Array,
    modify: Boolean,
    cat_type: Number,
    structures: Array,
  },

  data: () => ({
    menu: false,
    valid: true,
    editedItem: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    parent: [],
    struct_id: [],
    color: "",
  }),

  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.color,
        border: this.color ? "" : "solid",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Categorie"
        : "Modifier Categorie";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.$refs.form2.resetValidation();
      if (this.item.color) this.color = this.item.color;
      if (this.editedItem.fk_parent != 0) {
        this.parent.push(this.editedItem["fk_parent"]);
      }

      if (this.editedItem.id > 0 && this.editedItem.struct_id)
        this.struct_id.push(this.editedItem.struct_id);
    }
  },
  methods: {
    parent_change(item) {
      this.parent = item;
      this.editedItem.struct_id = item.struct_id;
    },
    struct_change(item) {
      this.struct_id = item;
    },
    close() {
      this.$emit("close", this.editedItem);
    },
    save() {
      if (this.$refs.form2.validate()) {
        if (
          this.editedItem.id < 0 &&
          this.cat_type == 4 &&
          this.$store.state.me.struct_id &&
          !this.$store.state.isadmin
        ) {
          this.struct_id = [];
          this.struct_id.push(this.$store.state.me.struct_id);
        }
        this.editedItem.fk_parent =
          this.parent.length > 0 ? this.parent.toString() : "0";
        if (this.editedItem.fk_parent == 0 && this.struct_id.length > 0)
          this.editedItem.struct_id = this.struct_id.toString();
        this.editedItem.color = this.color ? this.color : null;
        if (this.editedItem.id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_CATEGORIE,
              variables: {
                categorie: {
                  id: this.editedItem.id,
                  label: this.editedItem.label,
                  fk_parent: this.editedItem.fk_parent,
                  struct_id: this.editedItem.struct_id,
                  ref_ext: this.editedItem.ref_ext,
                  type_cat: this.type_cat,
                  description: this.editedItem.description,
                  color: this.editedItem.color,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.editedItem.add = false;
              this.$emit("change", this.editedItem);
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_CATEGORIE,
              variables: {
                categorie: {
                  label: this.editedItem.label,
                  fk_parent: this.editedItem.fk_parent,
                  struct_id: this.editedItem.struct_id,
                  ref_ext: this.editedItem.ref_ext,
                  type_cat: this.type_cat,
                  description: this.editedItem.description,
                  color: this.editedItem.color,

                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createCategorie.id;
              this.editedItem.add = true;
              this.$emit("add", this.editedItem);
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        }
      }
    },
  },
};
</script>
