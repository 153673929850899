<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'orders'"
          :AllResponse="'allorders'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Ordres'"
          :sheetname="'Ordres'"
          @open="OpenOrderForm"
          @rowselect="OrderChange"
          :showedit="false"
          :btn1="solder ? 'Solder' : ''"
          :btn1_disable="order.statut_id != '7'"
          @click1="solder_of"
          :key="klist"
          :Add="code_app == 4 && editer"
          :del="code_app == 4 && editer"
          :del_disable="order.qte_fab > 0 || order.nb_fiches > 0"
          :search_elm="filtre"
          :TypeScope="code_app == 3 ? 1 : 6"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="code_app == 4">
        <listitems
          :list="boms_list"
          :title="'Articles réservés'"
          :headers="boms_articles_headers"
          :key="kml"
          :master="false"
          :showedit="false"
          :add="false"
          :del="false"
          :exp_excel="false"
          :print="true"
          @rowselect="BomsChange"
          @open="BomsOpen"
          :filename="'Matiére'"
          :sheetname="'Matiére'"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="code_app == 4 && gammes > 0">
        <listitems
          :list="encours_list"
          :title="'Encours Production'"
          :headers="encours_header"
          :key="kml2"
          :master="false"
          :showedit="false"
          :add="false"
          :del="false"
          :Total="false"
          @open="BTsOpen"
          :exp_excel="false"
          :filename="'Encours'"
          :sheetname="'Encours'"
        >
        </listitems>
      </v-col>
    </v-row>
    <orderform
      :item="order"
      :key="korder"
      :showForm="!isOrderClosed"
      @close="closeOrderForm"
      :code_app="code_app"
      :editer="editer"
      :valider="valider"
      :solder="solder"
      :man_products="man_products"
      :units="units"
      :templates="templates"
      :attributes="attributes"
      :boms_list="list_boms"
      :gammes="gammes_list"
      @refresh="refresh"
    >
    </orderform>

    <formlist
      :title="title"
      :list="boms_articles"
      :list2="prod_list"
      :showform="!isListClose"
      :listheaders="list_headers"
      :list2headers="prod_headers"
      :key="frml"
      :imp_excel="false"
      :btn_save_caption="btn_save_caption"
      :btn_save="btn_save"
      :btn_save_dis="false"
      :width="width"
      @close="isListClose = true"
      @save="save"
      :Total="true"
      :master="true"
      :editer="true"
      :showstd="true"
      :alert="alert"
      @input="alert = false"
      :type_alert="type_alert"
      :message="message"
      :progress="progress"
    >
    </formlist>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import ORDERS from "../graphql/Production/ORDERS.gql";
import ALLORDERS from "../graphql/Production/ALLORDERS.gql";
import DELETE_ORDER from "../graphql/Production/DELETE_ORDER.gql";
//import ALLDETAILSCMDS_LIGHT from "../graphql/Mouvment/ALLDETAILSCMDS_LIGHT.gql";
import MAN_PRODUCTS from "../graphql/Production/MAN_PRODUCTS.gql";
import UPDATE_ORDER from "../graphql/Production/UPDATE_ORDER.gql";
import SOLDER_BT from "../graphql/Production/SOLDER_BT.gql";
import ALLGAMMES from "../graphql/Production/ALLGAMMES.gql";
import DATA from "../graphql/Commande/DATA_SUIVI.gql";
import UPDATE_DMS_ARTICLE from "../graphql/Production/UPDATE_DMS_ARTICLE.gql";
import PRODUCTION_LIST_CUMULE from "../graphql/Production/PRODUCTION_LIST_CUMULE.gql";
import PRODUCT_LIST_CUMULE from "../graphql/Production/PRODUCT_LIST_CUMULE.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    orderform: () => import("../components/OrderForm.vue"),
    formlist: () => import("../components/FormList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    code_app: Number,
  },
  data: () => ({
    alert: false,
    type_alert: "info",
    message: "",
    title: "",
    progress: false,
    filtre: "",
    btn_save: true,
    editer: false,
    valider: false,
    solder: false,
    klist: 100,
    kml: 200,
    kfiche: 300,
    kml2: 400,
    width: "1200px",
    drawer: null,
    btn_save_caption: "Enregistrer",
    isListClose: true,
    selitem: {},
    headers: [
      {
        text: "Type",
        value: "type_label",
        enum: "TYPE_LABEL",
        slot: "chip",
        color: "type_color",
        list: ["R", "L"],
        selected: true,
      },
      {
        text: "Code",
        value: "no_order",
        enum: "NO_ORDER",
        slot: "href",
        width: "150",
        selected: true,
      },

      {
        text: "Date OF",
        value: "date_order",
        enum: "DATE_ORDER",
        slot: "date",
        width: "110",
        selected: false,
      },
      {
        text: "Avant le",
        value: "date_limite",
        enum: "DATE_LIMITE",
        slot: "date",
        expire: true,
        width: "110",
        selected: true,
      },
      {
        text: "Délai(j)",
        value: "j_reste",
        enum: "J_RESTE",
        slot: "cur22",
        dec: 0,
        expire: true,
        selected: true,
        align: "center",
      },
      {
        text: "Cmd/Client",
        value: "cmd_label",
        enum: "CMD_LABEL",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        selected: true,
      },

      {
        text: "Qte Lancée",
        value: "qte_lanc",
        enum: "QTE_LANC",
        slot: "cur",
        align: "end",
        width: "110",
        selected: true,
      },

      {
        text: "Encours",
        value: "qte_encours",
        enum: "QTE_ENCOURS",
        slot: "cur",
        align: "end",
        width: "110",
        hiden: true,
        selected: false,
      },
      {
        text: "Qte Fab",
        value: "qte_fab",
        enum: "QTE_FAB",
        slot: "cur",
        align: "end",
        width: "110",
        hiden: true,
        selected: false,
      },

      {
        text: "% Fab",
        value: "prc_fab",
        enum: "PRC_FAB",
        slot: "progress",
        align: "center",
        width: "90",
        hiden: true,
        selected: true,
      },
      {
        text: "Qte NC",
        value: "qte_nc",
        enum: "QTE_NC",
        slot: "cur",
        align: "end",
        width: "110",
        hiden: true,
        selected: false,
      },

      {
        text: "% NC",
        value: "prc_nc",
        enum: "PRC_NC",
        slot: "progress",
        align: "center",
        width: "90",
        hiden: true,
        selected: true,
      },
      {
        text: "Disponible",
        value: "qte_stock",
        enum: "QTE_STOCK",
        slot: "cur",
        align: "end",
        width: "110",
        selected: true,
      },
      {
        text: "Qte Rcpt",
        value: "qte_receipt",
        enum: "QTE_RECEIPT",
        slot: "cur",
        align: "end",
        width: "110",
        selected: true,
      },

      {
        text: "% Rcpt.",
        value: "prc_receipt",
        enum: "PRC_RECEIPT",
        slot: "progress",
        align: "center",
        width: "90",
        cloture: true,
        selected: true,
      },

      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: true,
      },
    ],
    bomsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },

      {
        text: "Unité",
        value: "unit_name",
        selected: true,
      },
      {
        text: "Qte Rsrv.",
        value: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Srt.",
        value: "qte_liv",
        slot: "cur",
        align: "end",
        width: "120px",
        selected: true,
        dec: 2,
      },

      {
        text: "Qte Ret.",
        value: "qte_ret",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },

      {
        text: "Tol(%)",
        value: "tol",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
    ],
    encours_header: [
      {
        text: "N°",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Etat",
        value: "statut",
        selected: true,
      },
      {
        text: "Planifiée",
        value: "qte_lanc",
        align: "end",
        slot: "cur",
        total: "qte_lanc",
        selected: true,
      },
      {
        text: "Stock",
        value: "qte_rest",
        align: "end",
        slot: "cur",
        total: "qte_rest",
        selected: true,
      },
      {
        text: "Cumulée",
        value: "qte_fab",
        align: "end",
        slot: "cur",
        total: "qte_fab",
        selected: true,
      },
      {
        text: "BT",
        value: "qte_bt",
        align: "end",
        slot: "href22",
        total: "qte_bt",
        selected: true,
      },
    ],
    articles_headers: [
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_cmpt",
        align: "end",
        selected: true,
        slot: "cur",
      },

      {
        text: "Qte Srt.",
        value: "qte_liv",
        slot: "cur",
        align: "end",
        width: "120px",
        selected: true,
        dec: 2,
      },

      {
        text: "Qte Ret.",
        value: "qte_ret",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Rsrv.",
        value: "qte",
        align: "end",
        selected: true,
        total: "qte",
        slot: "href22",
        edit: true,
        type: "qte",
        oblig: false,
        sm: 12,
        md: 6,
      },
    ],
    boms_articles_headers: [
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit",
        value: "product_label",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },

      {
        text: "Qte Rsrv.",
        value: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Srt.",
        value: "qte_liv",
        slot: "cur",
        align: "end",
        width: "120px",
        selected: true,
        dec: 2,
      },

      {
        text: "Qte Ret.",
        value: "qte_ret",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Tol(%)",
        value: "tol",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
    ],
    bts_headers: [
      {
        text: "N° BT",
        value: "no_bt",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        selected: true,
      },

      {
        text: "Qte Lanc.",
        value: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Fab.",
        value: "qte_fab",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
    ],
    intr_headers: [
      {
        text: "Ref",
        value: "article_code",
        selected: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "product_label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        hiden: true,
        selected: true,
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        total: "qte",
        slot: "cur",
        align: "end",
      },
      {
        text: "Qte",
        value: "qte_rest",
        selected: true,
        total: "qte_rest",
        slot: "cur",
        align: "end",
      },
      {
        text: "U.M.",
        value: "unit",
        selected: true,
      },
      {
        text: "Nbr Item",
        value: "nbpfd",
        slot: "href22",
        selected: true,
        align: "end",
      },
    ],
    prod_headers: [
      {
        text: "Ref",
        value: "article_code",
        selected: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "product_label",
        selected: true,
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        total: "qte",
        slot: "cur",
        align: "end",
      },
      {
        text: "Qte",
        value: "qte_rest",
        selected: true,
        total: "qte_rest",
        slot: "cur",
        align: "end",
      },
      {
        text: "U.M.",
        value: "unit",
        selected: true,
      },
      {
        text: "Nbr Item",
        value: "nbpfd",
        slot: "href22",
        selected: true,
        align: "end",
      },

      {
        text: "Etape",
        value: "gamme_name",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        selected: true,
      },
    ],
    list_headers: [],
    isOrderClosed: true,
    isOrderDecoupeClosed: true,
    order: { gammes: [] },
    fiche: {},
    boms: {},
    list_boms: [],
    gammes_list: [],
    templates: [],
    attributes: [],
    units: [],
    frml: 600,
    changed: false,
    korder: 0,
    korderd: 1000,
    loading: false,
    man_products: [],
    boms_articles: [],
    int_list: [],
    prod_list: [],
    gammes: 0,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    colis_att() {
      return this.order.ppattributs
        ? this.order.ppattributs.split(",").length > 0
        : false;
    },

    Qselect() {
      return ORDERS;
    },
    Qselectall() {
      return ALLORDERS;
    },
    Qdelete() {
      return DELETE_ORDER;
    },

    boms_list() {
      return this.order ? this.order.boms : [];
    },

    encours_list() {
      return this.order.gammes ? this.order.gammes : [];
    },
  },
  watch: {
    code_app: {
      async handler() {
        this.load_data();
      },
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },
  async mounted() {
    this.load_data();
    this.editer =
      this.$store.state.auth.includes("02035") ||
      this.$store.state.auth.includes("02036") ||
      this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03035") ||
      this.$store.state.auth.includes("03036") ||
      this.$store.state.isadmin;
    this.solder =
      this.$store.state.auth.includes("04035") ||
      this.$store.state.auth.includes("04036") ||
      this.$store.state.isadmin;
  },

  methods: {
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async refresh() {
      // if (this.code_app == 3) {
      //   let v = {
      //     where: {
      //       AND: [
      //         { column: "STATUT_ID", value: 7 },
      //         { column: "CMD_TYPE", operator: "IN", value: [1, 3] },
      //         { column: "TOMANUFACTURE", value: 1 },
      //         { column: "REST_LANC", operator: "GT", value: 0 },
      //       ],
      //     },
      //   };
      //   let r = await this.requette(ALLDETAILSCMDS_LIGHT, v);
      //   if (r) {
      //     this.act_products = r.alldetailscmds_light;
      //   }
      // }
      if (this.code_app == 4) {
        let v = {
          where: {
            AND: [
              { column: "TOMANUFACTURE", value: "1" },
              { column: "BOM_OK", value: "1" },
            ],
          },
        };
        let r = await this.requette(MAN_PRODUCTS, v);
        if (r) {
          this.man_products = r.allproducts;
        }
        r = await this.requette(ALLGAMMES);
        if (r) {
          this.gammes_list = r.gammes;
        }
      }
    },
    async load_data() {
      this.headers[8].hiden = this.code_app == 3;
      this.headers[9].hiden = this.code_app == 3;
      this.headers[10].hiden = this.code_app == 3;
      this.headers[11].hiden = this.code_app == 3;
      this.headers[12].hiden = this.code_app == 3;
      //this.headers[15].hiden = this.code_app == 4;
      let r = await this.requette(DATA);
      if (r) {
        this.templates = r.alltemplates;
        this.attributes = r.allattributes;
        this.units = r.units;
        this.list_boms = r.boms;
      }
      await this.refresh();
    },
    async solder_of() {
      let v;
      let ok = true;
      if (this.order.fiches.findIndex((elm) => elm.statut_id == "18") >= 0) {
        this.snackbar_text =
          "Fiche de production encours - Operation impossible";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }
      //verifier intrant et encours
      if (ok) {
        let v = {
          of_id: this.order.id,
        };
        let r = await this.requette(PRODUCT_LIST_CUMULE, v);
        if (r) {
          this.boms_articles = r.product_list_cumule;
        }
        v = {
          of_id: this.order.id,
        };

        r = await this.requette(PRODUCTION_LIST_CUMULE, v);
        if (r) {
          this.prod_list = r.production_list_cumule;
        }
        this.width = "1400px";
        this.list_headers = this.intr_headers;
        this.isListClose = false;
        this.btn_save_caption = "Solder";
        this.frml++;
        ok = false;
      }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir solder cet OF ?"
          )
        ) {
          v = {
            order: {
              id: this.order.id,
              statut_id: "9",
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(UPDATE_ORDER, v, true);
          if (r) {
            //update all bts
            v = {
              of_id: this.order.id,
              write_uid: this.$store.state.me.id,
            };
            await this.maj(SOLDER_BT, v);

            this.klist++;
          }
        }
      }
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change", this.editedItem);
          }
          r = data.data;

          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    BomsChange(item) {
      this.boms = item;
    },
    BTsOpen(item) {
      this.btn_save = false;
      this.width = "800px";
      this.list_headers = this.bts_headers;
      this.btn_save_caption = "Enregistrer";
      this.boms_articles = item.bts;
      this.title = "Bons de Travail de : " + item.caption;

      this.isListClose = false;
      this.frml++;
    },
    BomsOpen() {
      // this.btn_save = true;
      // this.width = "1200px";
      // this.list_headers = this.articles_headers;
      //this.btn_save_caption="Enregistrer"
      // this.boms_articles = [];
      // this.boms = item;
      // this.title =
      //   "Articles du produit : " +
      //   this.boms.label +
      //   " (Qte Rsrv : " +
      //   this.boms.qte +
      //   " " +
      //   this.boms.unit +
      //   ")";
      // this.boms.forEach((element) => {
      //   element.qte_max = element.stock_cmpt
      //     ? element.stock_cmpt > this.boms.qte
      //       ? this.boms.qte
      //       : element.stock_cmpt
      //     : 0;
      //   element.qte_min = element.qte_liv - element.qte_ret;
      //   this.boms_articles.push(element);
      // });
      // this.isListClose = false;
      // this.frml++;
    },
    async save() {
      this.alert = false;
      this.message = "";
      let tot = this.boms_articles.reduce((a, b) => a + (b["qte"] || 0), 0);
      if (tot > this.boms.qte) {
        this.message =
          "Erreur!! Quantité Max " + this.boms.qte + " " + this.boms.unit;
        this.alert = true;
        this.type_alert = "error";
      } else {
        let list = [];
        this.boms_articles.forEach((element) => {
          list.push({ id: element.id, qte: element.qte });
        });
        let v = {
          list: list,
          create_uid: this.$store.state.me.id,
        };
        await this.maj(UPDATE_DMS_ARTICLE, v);
        list.forEach((element) => {
          let i = this.boms.findIndex((elm) => elm.id == element.id);
          if (i >= 0) {
            this.boms[i].qte = element.qte;
          }
        });
        this.isListClose = true;
      }
    },
    OrderChange(item) {
      this.order = item;
      this.gammes = 0;
      if (this.order.gammes) this.gammes = this.order.gammes.length;
      this.selitem = item;
      this.encours_header[2].text =
        "Planifiée " + (this.order.unit ? "(" + this.order.unit + ")" : "");
      this.encours_header[2].dec = this.order.decimal ? this.order.decimal : 2;
      this.encours_header[3].text =
        "Stock " + (this.order.unit ? "(" + this.order.unit + ")" : "");
      this.encours_header[3].dec = this.order.decimal ? this.order.decimal : 2;
      this.encours_header[4].text =
        "Cumulée " + (this.order.unit ? "(" + this.order.unit + ")" : "");
      this.encours_header[4].dec = this.order.decimal ? this.order.decimal : 2;
      this.kml++;
      this.kml2++;
    },
    closeOrderDecoupeForm(item) {
      this.selitem = item;
      this.order = item;
    },
    OpenOrderForm(item) {
      this.order = item;
      this.isOrderClosed = false;
      this.korder++;
    },
    closeOrderForm(item) {
      this.selitem = item;
      this.order = item;
      this.isOrderClosed = true;
    },

    FicheChange(item) {
      this.fiche = item;
    },
  },
};
</script>
