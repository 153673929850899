<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show="!edition">
        <listitemspagepage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'cmds_list'"
          :AllResponse="'cmds_list_all'"
          :headers="headers"
          :field_list="['SIGN_DATE', 'NOCMD']"
          :filename="'Commandes'"
          :sheetname="'Commandes'"
          @open="NewCmdForm"
          @rowselect="CmdChange"
          :showedit="false"
          :key="klist"
          :TypeScope="tiertype"
          :Kind="valider ? 1 : 0"
          :Add="editer"
          :del="false"
          :search_elm="filtre"
        >
        </listitemspagepage>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <cmdprint
          :key="kprint"
          :cmd="cmde"
          :tiertype="tiertype"
          :tier_type_prix="tier_type_prix"
          :qte_prix="qte_prix"
          :editer="editer"
          :amender="amender"
          :valider="valider"
          :solder="solder"
          :modes="modes"
          :modalites="modalites"
          :ports="ports"
          @cancel="cmde = selitem"
          @change="CmdChange"
          @delete="CmdChange"
          :edition="edition"
          @edition="edit_change"
          :currencies="currencies"
          :units="units"
          :commandes="allcmds"
          :tiers_agences="tiers_agences"
          :products_categories="products_categories"
          :tiers_categories="tiers_categories"
          :loading="data_load"
          @options="get_option"
          :ipg="options.itemsPerPage"
        >
        </cmdprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CMD_LIST from "../graphql/Commande/CMD_LIST.gql";
import CMD from "../graphql/Commande/CMD.gql";
import ALLCMDS_LIST from "../graphql/Commande/ALLCMDS_LIST.gql";
import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import DATA from "../graphql/Commande/DATA.gql";
import PRICES from "../graphql/Commande/PRICES.gql";
import DELETE_CMD from "../graphql/Commande/DELETE_CMD.gql";
import MODES from "../graphql/Paiement/MODES.gql";
import UPDATE_CMD from "../graphql/Commande/UPDATE_CMD.gql";
//import { spec } from "print/data.js";

export default {
  components: {
    listitemspagepage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    cmdprint: () => import("../components/CmdPrint.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: "",
    editer: false,
    amender: false,
    solder: false,
    valider: false,
    klist: 100,
    data_load: false,
    drawer: null,
    selitem: {},
    edition: false,
    headers: [
      {
        text: "Type",
        value: "contrat_type",
        enum: "CONTRAT_TYPE",
        title: true,
        selected: false,
        sm: 4,
        md: 4,
      },
      {
        text: "N°",
        value: "nocmd",
        enum: "NOCMD",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "N° Contrat",
        value: "contrat",
        enum: "CONTRAT",
        title: true,
        selected: false,
        sm: 4,
        md: 4,
      },
      {
        text: "Date",
        value: "sign_date",
        enum: "SIGN_DATE",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        sm: 6,
        md: 6,
      },
      {
        text: "Libelle",
        value: "label",
        enum: "LABEL",
        selected: false,
        sm: 2,
        md: 2,
      },
      {
        text: "Montant",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        selected: false,
        sm: 6,
        md: 6,
      },
      {
        text: "Statut",
        value: "statut_id",
        selected: false,
        hiden: true,
      },
      {
        text: "Ref.",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: false,
        sm: 2,
        md: 2,
      },
      {
        text: "Int/Ext",
        value: "interne",
        enum: "INTERNE",
        selected: false,
      },
      {
        text: "Ref.",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: false,
      },
      {
        text: "Etablit Par",
        value: "contact",
        enum: "CONTACT",
        selected: false,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "right",
        expire: true,
        selected: true,
        sm: 4,
        md: 4,
      },
    ],
    cmde: {},
    kprint: 100,
    tier_type_prix: [],
    currencies: [],
    qte_prix: [],
    loading: false,
    modes: [],
    modalites: [],

    ports: [],
    units: [],
    options: {},
    allcmds: [],
    tiers_agences: [],
    products_categories: [],
    tiers_categories: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return CMD_LIST;
    },
    Qselectall() {
      return ALLCMDS_LIST;
    },
    Qdelete() {
      return DELETE_CMD;
    },
  },
  watch: {
    tiertype: {
      async handler() {
        this.cmde = {};
        this.$store.dispatch("Changed", true);
        await this.get_data();
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
    this.headers[4].text = this.tiertype == 1 ? "Client" : "Fournisseur";
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  async mounted() {
    this.editer =
      (this.tiertype == 1 && this.$store.state.auth.includes("02006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("02005")) ||
      (this.tiertype == 3 && this.$store.state.auth.includes("02077")) ||
      (this.tiertype == 5 && this.$store.state.auth.includes("02084")) ||
      (this.tiertype == 6 && this.$store.state.auth.includes("02085")) ||
      (this.tiertype == 4 && this.$store.state.auth.includes("02076")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.tiertype == 1 && this.$store.state.auth.includes("03006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("03005")) ||
      (this.tiertype == 3 && this.$store.state.auth.includes("03077")) ||
      (this.tiertype == 4 && this.$store.state.auth.includes("03076")) ||
      (this.tiertype == 5 && this.$store.state.auth.includes("03084")) ||
      (this.tiertype == 6 && this.$store.state.auth.includes("03085")) ||
      this.$store.state.isadmin;

    this.solder =
      (this.tiertype == 1 && this.$store.state.auth.includes("04006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("04005")) ||
      (this.tiertype == 3 && this.$store.state.auth.includes("04077")) ||
      (this.tiertype == 4 && this.$store.state.auth.includes("04076")) ||
      (this.tiertype == 5 && this.$store.state.auth.includes("04084")) ||
      (this.tiertype == 6 && this.$store.state.auth.includes("04085")) ||
      this.$store.state.isadmin;

    this.amender =
      (this.tiertype == 1 && this.$store.state.auth.includes("05006")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("05005")) ||
      this.$store.state.isadmin;
    await this.get_data();
    //retour de reception/livraison/Produit

    if (this.$route.params.cmd) {
      this.filtre = this.$route.params.cmd.nocmd;
      this.selitem = this.$route.params.cmd;
      this.cmde = this.selitem;
      this.kprint++;
      this.klist++;
    }
    // if (this.$route.params.tier_id) {
    //   this.cmde.id = -1;
    //   this.cmde.tier_id = this.$route.params.tier_id;
    //   this.edition = true;
    //   this.kprint++;
    // }
  },

  methods: {
    get_option(item) {
      this.options = item;
    },
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.cmde.id > 0) {
        let v = {
          cmd: {
            id: this.cmde.id,
            modif_by: null,
            modif_date: null,
          },
        };

        await this.maj(UPDATE_CMD, v);
      }
    },

    edit_change(value) {
      this.edition = value;
    },

    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },

    async get_data() {
      this.loading = true;
      let r;

      if (this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) {
        //this.klist++;
        r = await this.requette(PRICES);
        if (r) {
          this.tier_type_prix = r.tier_type_prix;
          this.qte_prix = r.qte_prix;
        }
      }

      let v;
      if (this.tiertype >= 3) {
        v = {
          TypeScope: this.tiertype,
          where: {
            AND: [
              { column: "CONTRAT_ID", operator: "IS_NULL" },
              { column: "LIV_REST", operator: "GTE", value: 0 },
            ],
          },
        };
        r = await this.requette(ALLCMDS, v);
        if (r) {
          this.allcmds = r.allcmds;
        }
      }

      r = await this.requette(DATA);
      if (r) {
        this.ports = r.ports;
        this.units = r.units.filter((elm) => elm.uom_type == "m");
        this.currencies = r.currencies;
        this.tiers_agences = r.tiers_agences;
        this.products_categories = r.products_categories;
        this.tiers_categories = r.tiers_categories;
      }
      r = await this.requette(MODES);
      if (r) {
        this.modes = r.modes;
        this.modalites = r.modalites;
      }

      this.loading = false;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async CmdChange(item) {
      if (item)
        if (item.id) {
          this.data_load = true;
          let r = await this.requette(CMD, {
            where: { column: "ID", value: item.id },
          });
          if (r) {
            this.cmde = r.cmds.data[0];
            this.selitem = this.cmde;
            this.kprint++;
            this.data_load = false;
          }
        } else {
          this.cmde = {};
          this.selitem = item;
          this.kprint++;
        }
    },
    NewCmdForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.kprint++;
      this.cmde = item;
    },
  },
};
</script>
