<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showpaye"
        max-width="800px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card :loading="progress" class="mx-auto overflow-hidden">
          <v-toolbar color="#73DDFF" class="mb-6">
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <label v-if="editedItem.status_name != 'Brouillon'" class="mr-3">
              Statut : {{ editedItem.status_name }}</label
            >
            <v-btn text @click="print_paye" v-if="editedItem.statut_id == '17'"
              ><v-icon> mdi-printer </v-icon>
            </v-btn>
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                payedetails.length == 0 &&
                editedItem.statut_id == '17'
              "
              ><v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="editedItem.id > 0"
                  @click.stop="drawer = !drawer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique</span>
            </v-tooltip>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row dense>
                <!-- Reference -->
                <v-col cols="12" sm="4" md="4" lg="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.paye_ref"
                    label="Reference"
                    :readonly="!modify"
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <!-- Date Operation -->

                <v-col cols="12" sm="4" md="4" lg="4">
                  <datepicker
                    :label="'Date Operation'"
                    v-model="editedItem.paye_date"
                    :rules="[(v) => !!v || 'Date obligatoire']"
                    :edit="modify"
                    :dense="true"
                    :key="dp"
                    :clearable="false"
                    :date_max="$store.state.today"
                  ></datepicker>
                </v-col>
              </v-row>
              <v-row dense>
                <!-- SITE COMPTE ????????? -->

                <!-- <v-col
                  cols="12"
                  sm="5"
                  md="5"
                  v-if="paye_type == 1 && comptes.length > 1"
                >
                  <v-select
                    :items="comptes"
                    v-model="editedItem.compte_id"
                    :item-text="'site_compte'"
                    :item-value="'id'"
                    :rules="[(v) => !!v || 'Compte obligatoire']"
                    :label="'Compte'"
                    :readonly="!modify"
                  ></v-select>
                </v-col> -->

                <!-- Tier -->
                <v-col cols="12" sm="8" md="8" lg="8">
                  <cursorselect
                    :Qsearch="tierscursor"
                    :Qresp="'tiers_cursor'"
                    :value="editedItem.tier_id"
                    :text_fields="['code_client', 'code_fournisseur', 'nom']"
                    :variableadd="variableadd"
                    :item_text="'tier_name'"
                    @change="tier_change"
                    :rules="true"
                    :label="paye_type == 3 ? 'Tier' : 'Beneficiaire'"
                    :readonly="!modify || facture"
                    :key="cs"
                    v-if="editedItem.statut_id == '16'"
                  >
                  </cursorselect>

                  <h2 v-else class="pa-4">
                    {{
                      (paye_type == 3 ? "Tier : " : "Beneficiaire : ") +
                      editedItem.tier_name
                    }}
                  </h2>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="editedItem.facture == '1' && paye_type == '1'"
                >
                  <v-autocomplete
                    v-model="selected_dps"
                    :item-value="'id'"
                    :item-text="
                      (item) =>
                        item.nodp +
                        ' du ' +
                        datefr(item.dp_date, false, false) +
                        ' Montant : ' +
                        numberWithSpace(
                          item.ttc_reste ? item.ttc_reste.toFixed(2) : '0.00'
                        )
                    "
                    :items="dps_list"
                    @change="dp_change"
                    :filter="customFilter2"
                    :label="'Demandes de Paiement'"
                    :rules="[
                      (v) =>
                        selected_dps.length > 0 ||
                        'Demande de Paiement obligatoire',
                    ]"
                    dense
                    :readonly="!modify || facture"
                    v-if="editedItem.statut_id == '16'"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <!-- Montant  -->

                <v-col cols="12" sm="8" md="8" lg="4">
                  <currencyinput
                    class="mt-n2"
                    label="Montant"
                    v-model="editedItem.montant"
                    :rules="[
                      (v) => !!v || 'Montant obligatoire',
                      (v) =>
                        v <= montant_max ||
                        facture ||
                        paye_type == 3 ||
                        'Montant max : ' + montant_max,
                    ]"
                    :readonly="!modify || facture"
                    :key="mk"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    dense
                    :items="modes_list"
                    v-model="editedItem.modepaiement"
                    item-text="mode"
                    item-value="id"
                    item-disabled="disabled"
                    label="Mode de Reglement"
                    :readonly="!modify || freeze_md"
                    :rules="[(v) => !!v || 'Mode de Reglement obligatoire']"
                    @input="change_mode"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="4" md="4" v-if="paye_bl">
                  <v-select
                    dense
                    :items="[
                      {
                        id: '0',
                        text: paye_type == 3 ? 'Expedition' : 'Reception',
                      },
                      {
                        id: '1',
                        text: 'Facture',
                      },
                    ]"
                    v-model="editedItem.facture"
                    item-text="text"
                    item-value="id"
                    :label="paye_type == 3 ? 'Encaissement ' : 'Paiement'"
                    :readonly="!modify"
                    v-if="espece == 1 && !facture"
                    :rules="[(v) => !!v || 'Type obligatoire']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="espece == 2">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="mp == '3' && paye_type == 1"
                >
                  <v-autocomplete
                    :items="tiers_agences_list"
                    :item-value="'id'"
                    item-text="agence"
                    dense
                    v-model="editedItem.agence_id"
                    :label="'Agence'"
                    :readonly="!modify"
                    @change="agence_change"
                    :rules="[(v) => !!v || 'Agence obligatoire']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="paye_type == 3">
                  <v-autocomplete
                    :items="banks"
                    :item-value="'id'"
                    item-text="bank"
                    dense
                    v-model="editedItem.bank_id"
                    :label="'Banque'"
                    :readonly="!modify"
                    :rules="[(v) => !!v || 'Banque obligatoire']"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    ref="chq"
                    autocomplete="off"
                    dense
                    v-model="editedItem.nocheque"
                    :label="
                      mp == '1'
                        ? 'No Cheque'
                        : mp == '2'
                        ? 'Ref. Versement'
                        : mp == '3' && paye_type == 1
                        ? 'N° Compte'
                        : 'Ref. Virement'
                    "
                    :readonly="(mp == '3' && paye_type == 1) || !modify"
                    :rules="[
                      (v) =>
                        !!v ||
                        (mp == '1'
                          ? 'No Cheque obligatoire'
                          : mp == '2'
                          ? 'Ref. Versement obligatoire'
                          : mp == '3' && paye_type == 1
                          ? 'N° Compte obligatoire'
                          : 'Ref. Virement obligatoire'),
                    ]"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <datepicker
                    :label="
                      'Date' +
                      (mp == '1'
                        ? ' Cheque'
                        : mp == '2'
                        ? ' Versement '
                        : ' Virement ')
                    "
                    v-model="editedItem.date_cheque"
                    :rules="[(v) => !!v || 'Date obligatoire']"
                    :date_max="$store.state.today"
                    :edit="modify"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="paye_type == 3">
                  <v-autocomplete
                    :items="agences"
                    :item-value="'id'"
                    item-text="agence"
                    dense
                    v-model="editedItem.agence_id"
                    :label="'Agence de remise'"
                    :readonly="!modify"
                    :rules="[(v) => !!v || !tovalid || 'Agence obligatoire']"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="paye_type == 3 && mp == '1'"
                >
                  <datepicker
                    :label="'Date de remise'"
                    v-model="editedItem.date_remise"
                    :rules="[(v) => !!v || !tovalid || 'Date obligatoire']"
                    :date_max="$store.state.today"
                    :edit="modify"
                  ></datepicker>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="paye_type == 3 && mp != '4'"
                >
                  <datepicker
                    :label="'Date de rapprochement'"
                    v-model="editedItem.date_effet"
                    :rules="[(v) => !!v || !toaccept || 'Date obligatoire']"
                    :date_max="$store.state.today"
                    :edit="acceptance"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.motif"
                    label="Motif"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                    v-if="paye_type == 1"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row
                    v-if="editedItem.tier_id && editedItem.statut_id == '16'"
                  >
                    <v-col cols="12" sm="6" md="4">
                      <span
                        >Situation actuelle :
                        {{
                          numberWithSpace(
                            old_situation ? old_situation : "0.00"
                          )
                        }}</span
                      >
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <span
                        >Nouvelle Situation :
                        {{
                          numberWithSpace(
                            new_situation ? new_situation : "0.00"
                          )
                        }}</span
                      >
                    </v-col>
                  </v-row> -->
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="!facture && paye_type == 3"
                >
                  <v-checkbox
                    v-model="editedItem.avance"
                    label="Avance "
                    :readonly="!modify"
                    dense
                    class="my-1"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                  md="10"
                  v-if="!facture && paye_type == 3"
                >
                  <v-autocomplete
                    :items="cmds_list"
                    v-model="editedItem.cmd_id"
                    :disabled="editedItem.avance == 0"
                    :item-text="
                      (item) =>
                        item.nocmd +
                        ' - ' +
                        (item.label ? item.label : item.tier_name)
                    "
                    item-value="id"
                    :label="'Commande'"
                    :readonly="!modify"
                    :rules="[
                      (v) =>
                        !!v || editedItem.avance == 0 || 'Commande obligatoire',
                    ]"
                    dense
                    :loading="cmd_load"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="!facture && paye_type == 3 && ta"
                >
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model.number="editedItem.taux_avance"
                    :disabled="editedItem.avance == 0"
                    class="inputPrice"
                    type="number"
                    label="Taux Rembourssement"
                    :readonly="!modify"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    rows="2"
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>

            <!-- historique -->
            <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              right
              width="500"
            >
              <v-card flat>
                <v-card-title>
                  <v-toolbar color="grey lighten-2" flat>
                    <v-toolbar-title> Historique du Document </v-toolbar-title>
                  </v-toolbar>
                </v-card-title>
                <v-card-text>
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="(item, i) in operations"
                      :key="i"
                      fill-dot
                      small
                      :color="item.color"
                      :icon="item.icon"
                    >
                      <strong>{{ item.label }}</strong>
                      {{
                        " Par " +
                        item.user_name +
                        " le " +
                        datefr(item.created_at, true)
                      }}
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-navigation-drawer>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions class="py-3 pb-4">
            <v-btn
              color="blue darken-1"
              text
              @click="save('16')"
              v-if="modify && !facture"
              ><v-icon> mdi-content-save </v-icon>
              Enregistrer
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="valid_paye(17)"
              v-if="
                valider &&
                editedItem.statut_id == '16' &&
                editedItem.montant >= 0
              "
              ><v-icon> mdi-check-bold </v-icon>
              Valider
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="valid_paye(17)"
              v-if="
                valider &&
                editedItem.modepaiement != '4' &&
                editedItem.statut_id == '17' &&
                acceptance &&
                editedItem.montant >= 0
              "
              ><v-icon> mdi-check-bold </v-icon>
              Accepter
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="valid_paye(18)"
              v-if="
                valider &&
                editedItem.modepaiement != '4' &&
                editedItem.statut_id == '17' &&
                acceptance &&
                editedItem.montant >= 0
              "
              ><v-icon> mdi-cancel </v-icon>
              Rejeter
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click.stop="close"
              ><v-icon> mdi-close-thick </v-icon> Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
//import CREATE_PAYE from "../graphql/Paiement/CREATE_PAYE.gql";
import UPDATE_PAYE from "../graphql/Paiement/UPDATE_PAYE.gql";
import UPSERT_PAYE from "../graphql/Paiement/UPSERT_PAYE.gql";
import ACTUAL_CMDS from "../graphql/Mouvment/ACTUAL_CMDS.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import { myBL, ta, entites } from "print/data.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { addHeaders, addFooters, marges2 } from "print/entete.js";
import QRCode from "qrcode";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    currencyinput: () => import("./CurrencyInput.vue"),
  },
  name: "payeform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    paye_type: { type: Number, default: 1 },
    banks: Array,
    agences: Array,
    tiers_agences: Array,
    editer: Boolean,
    valider: Boolean,
    modes: Array,
    facts_list: Array,
    comptes: Array,
    document: Object,
  },
  data: () => ({
    cmds_list: [],
    drawer: null,
    drawer2: null,
    tabs: null,
    espece: 0,
    cs: 1200,
    dp: 1300,
    tovalid: false,
    toaccept: false,
    mp: null,
    panel: [0],
    mk: 100,
    paye_bl: false,
    fdocl: 1000,
    progress: false,
    valid: true,
    modify: false,
    acceptance: false,
    selected_dps: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      status_name: "Brouillon",
      statut_id: "16",
      paye_date: null,
      tier_id: null,
      factdetails: [],
      mvmdetails: [],
      payedetails: [],
      avance: 0,
      facture: "1",
      montant: null,
    },
    editedItem: {},
    aff: 0,
    freeze: false,
    freeze_md: false,
    loading: false,
    old_situation: 0,
    cmd_load: false,
  }),

  computed: {
    tiers_agences_list() {
      let l = [];
      if (this.tiers_agences)
        l = this.tiers_agences.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      return l;
    },
    facture() {
      let ok = false;
      if (this.document) if (this.document.id > 0) ok = true;
      return ok;
    },
    variableadd() {
      let w = {
        statut_id: [2, 3],
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    ta() {
      return ta;
    },
    dps_list() {
      let l = [];
      if (this.editedItem.tier_id)
        l = this.facts_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      return l;
    },
    entites() {
      return entites.find((elm) => elm.id == 1);
    },
    auth() {
      let a = this.paye_type == 1 ? "01016" : "01017";
      return a;
    },
    modes_list() {
      return this.modes.filter((elm) => elm.type == 0);
    },
    operations: function () {
      return this.item.id > 0 ? this.item.operations : [];
    },
    payedetails: function () {
      return this.item.payedetails ? this.item.payedetails : [];
    },

    showpaye() {
      return this.showForm;
    },

    formTitle() {
      let n, m;
      if (this.paye_type == 3) {
        n = "Nouveau Encaissement ";
        m = "Encaissement";
      }

      if (this.paye_type == 1) {
        n = "Nouveau Paiement ";
        m = "Paiement";
      }

      return this.editedItem.id < 0 ? n : m + " N° " + this.editedItem.nopaye;
    },
    montant_max() {
      return this.dps_list
        .filter((elm) => this.selected_dps.includes(elm.id))
        .reduce((a, b) => a + (parseFloat(b["ttc_reste"]) || 0), 0);
    },
    new_situation() {
      return this.editedItem.statut_id == "16"
        ? this.old_situation +
            (this.editedItem.montant ? this.editedItem.montant : 0)
        : this.old_situation;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
        this.mk++;
        this.fdocl++;
        if (this.editedItem.cmd_id) this.editedItem.avance = 1;
        else this.editedItem.avance = 0;
        this.espece = this.editedItem.modepaiement == "4" ? 1 : 2;
        this.mp = this.editedItem.modepaiement;
        this.selected_dps = this.payedetails.map((elm) => elm.dp_id);
        //this.tier_change(this.editedItem.tier_id);
      } else {
        this.defaultItem.paye_date = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.document.tier_id) {
          this.freeze = true;
          if (this.paye_type == 1) {
            this.selected_dps = [this.document.id];
            this.editedItem.facture = 1;
          } else this.editedItem.facture = this.document.facture;
          this.editedItem.tier_id = this.document.tier_id;
          this.editedItem.montant = this.document.mont_ttc;
          this.editedItem.paye_ref = this.document.nobon;
          this.mk++;
          this.editedItem.modepaiement = this.document.modepaiement;
          this.mp = this.editedItem.modepaiement;
          if (this.document.modepaiement == "4") {
            this.freeze_md = true;
            this.espece = 1;
            if (this.modes[3]) this.modes[3].disabled = false;
          } else {
            this.espece = 2;
            if (this.modes[3]) this.modes[3].disabled = true;
          }
        }

        if (this.comptes) {
          if (this.comptes.length == 1)
            this.editedItem.compte_id = this.comptes[0].id;
          else {
            let i = this.comptes.findIndex((elm) => elm.default == 1);
            if (i >= 0) this.editedItem.compte_id = this.comptes[i].id;
          }
        }
      }
      this.dp++;
    }
    this.modify = this.editer && this.editedItem.statut_id == "16";
    this.acceptance =
      this.editer &&
      this.editedItem.statut_id == "17" &&
      this.editedItem.date_effet == null;
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    )
      this.paye_bl = true;
  },

  methods: {
    agence_change() {
      let i = this.tiers_agences_list.findIndex(
        (elm) => elm.id == this.editedItem.agence_id
      );
      if (i >= 0 && this.editedItem.modepaiement == "3")
        this.editedItem.nocheque = this.tiers_agences_list[i].nocompte;
    },
    print_paye() {
      var doc;

      if (this.paye_type == 1) {
        if (this.editedItem.modepaiement == 1) {
          doc = new jsPDF("p", "mm", [180, 180]);
          doc.setFontSize(10);
          doc.text(
            this.numberWithSpace(
              parseFloat(this.editedItem.montant).toFixed(2)
            ),
            155,
            10
          );
          let doc_head = [];
          doc_head.push({
            lib1: {
              content: this.$lettre.NumberToLetter(this.editedItem.montant),
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
          doc.autoTable({
            margin: { left: 75, top: 20 },
            body: doc_head,
            columnStyles: {
              0: { halign: "left", cellWidth: "auto" },
            },
            theme: "plain",
            styles: {
              valign: "middle",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 0,
            },
            tableWidth: "120",
          });
          doc_head = [];
          doc_head.push({
            lib1: {
              content: this.editedItem.tier_name,
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
          doc.autoTable({
            startY: 30,
            margin: { left: 45, top: 30 },
            body: doc_head,
            columnStyles: {
              0: { halign: "left", cellWidth: "auto" },
            },
            theme: "plain",
            styles: {
              valign: "middle",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 0,
            },
            tableWidth: "120",
          });
          doc.text(this.entites.ville_name, 125, 44);
          doc.text(
            this.datefr(this.editedItem.paye_date, false, false),
            155,
            44
          );
          doc.setProperties({ title: "Cheque" });
        }
        if (this.editedItem.modepaiement == 3) {
          let bank;
          let i = this.comptes.findIndex(
            (elm) => elm.id == this.editedItem.compte_id
          );
          if (i >= 0) {
            bank = this.comptes[i];
          }
          doc = new jsPDF();
          let marges = marges2(doc, this.$store);
          let doc_head = [];
          doc_head.push({
            lib1: {
              content: this.entites.short_name ? this.entites.short_name : "",
              styles: { fontStyle: "bold", fontSize: 10 },
            },
            lib2: "",
          });
          doc_head.push({
            lib1: {
              content: "Réf. : " + this.editedItem.nopaye,
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            lib2: {
              content:
                this.entites.ville_name +
                " le : " +
                this.datefr(this.editedItem.paye_date, false, false),
              styles: { fontStyle: "normal", fontSize: 10, halign: "right" },
            },
          });
          doc_head.push({
            lib1: "",
            lib2: {
              content: bank ? bank.bank : "",
              styles: {
                fontStyle: "bold",
                fontSize: 12,
                halign: "left",
                cellPadding: { top: 10 },
              },
            },
          });
          doc_head.push({
            lib1: "",
            lib2: {
              content:
                "A Monsieur Le Directeur de l'Agence " +
                (bank ? bank.agence : ""),
              styles: {
                fontStyle: "bold",
                fontSize: 12,
                halign: "left",
              },
            },
          });
          doc_head.push({
            lib1: {
              content: "ORDRE DE VIREMENT",
              styles: {
                fontStyle: "bold",
                fontSize: 16,
                halign: "center",
                cellPadding: { top: 10, bottom: 10 },
              },
              colSpan: 2,
            },
          });
          doc_head.push({
            lib1: {
              content: "Monsieur Le Directeur, ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content:
                "Nous vous prions de bien vouloir exécuter par le débit de notre compte ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content: bank ? bank.compte : "",
              styles: {
                fontStyle: "bold",
                fontSize: 14,
                halign: "center",
                cellPadding: { top: 5, bottom: 5 },
              },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content: "le virement au profit de ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            lib2: {
              content: this.editedItem.tier_name,
              styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
            },
          });
          doc_head.push({
            lib1: {
              content: "Domicilié à ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            lib2: {
              content: this.editedItem.agence,
              styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
            },
          });
          doc_head.push({
            lib1: {
              content: "Compte N°: ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            lib2: {
              content: this.editedItem.nocheque,
              styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
            },
          });
          doc_head.push({
            lib1: {
              content: "Le montant En Chiffres ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            lib2: {
              content:
                this.numberWithSpace(
                  this.editedItem.montant
                    ? parseFloat(this.editedItem.montant).toFixed(2)
                    : 0
                ) + " DA",
              styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
            },
          });
          doc_head.push({
            lib1: {
              content:
                " (En Lettres) : " +
                this.$lettre.NumberToLetter(this.editedItem.montant),
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                cellPadding: { bottom: 10 },
              },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content:
                " En règlement de : " +
                (this.editedItem.motif ? this.editedItem.motif : ""),
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                cellPadding: { bottom: 5 },
              },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content:
                " Réference : " +
                (this.editedItem.paye_ref ? this.editedItem.paye_ref : ""),
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                cellPadding: { bottom: 10 },
              },
            },
            colSpan: 2,
          });
          doc_head.push({
            lib1: {
              content:
                "Avec nos remerciements, Veuillez agréer, Monsieur le Directeur, l'expréssion de nos salutations les meilleures.",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            colSpan: 2,
          });
          doc.autoTable({
            startY: marges.margin_top + marges.l_h,
            margin: { left: marges.margin_left },
            body: doc_head,
            columnStyles: {
              0: { halign: "left", cellWidth: "auto" },
            },
            theme: "plain",
            styles: {
              valign: "middle",
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellPadding: 0,
            },
            tableWidth: "120",
          });
          doc.setProperties({ title: "OV" });
          addHeaders(doc, this.$store, this.editedItem.compte, false);
          addFooters(doc, this.$store);
        }
        doc.autoPrint();
        window.open(
          doc.output("bloburl"),
          "_blank",
          "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=200,left=350,width=1000,height=500"
        );
      }
      if (this.paye_type == 3) {
        // Declare doc outside the asynchronous scope
        let doc;

        // Generate the QR code asynchronously
        QRCode.toDataURL(
          this.editedItem.nopaye +
            "-" +
            this.editedItem.paye_date +
            "-" +
            this.editedItem.tier_code +
            "-" +
            this.editedItem.tier_id
        )
          .then((url) => {
            // Create the PDF after QR code generation is successful
            doc = new jsPDF();
            doc.addImage(url, "PNG", 160, 90, 30, 30);
            doc.addImage(url, "PNG", 160, 250, 30, 30);

            // All other code to build the table and add it to the PDF
            const receiptNumber = this.editedItem.nopaye;
            const formattedPayDate =
              this.entites.ville_name +
              " le : " +
              this.datefr(this.editedItem.paye_date, false, false);

            // Certification details
            const amountReceived =
              this.numberWithSpace(
                this.editedItem.montant
                  ? parseFloat(this.editedItem.montant).toFixed(2)
                  : 0
              ) + " DA";

            // Building the table data
            const tableData = [
              [
                {
                  content: "Réf. : " + receiptNumber,
                  styles: { fontStyle: "bold", fontSize: 10 },
                },
                {
                  content: formattedPayDate,
                  styles: { fontStyle: "bold", fontSize: 10, halign: "right" },
                },
              ],
              ["", ""],
              ["", ""],
              [
                {
                  content: "TITRE D'ENCAISSEMENT",
                  styles: {
                    fontStyle: "bold",
                    fontSize: 16,
                    halign: "center",
                    cellPadding: { top: 10, bottom: 10 },
                  },
                  colSpan: 2,
                },
              ],
              [
                {
                  content:
                    "                    Nous certifions par le present titre d'encaissement avoir perçu ;  ",
                  styles: { fontStyle: "normal", fontSize: 12 },
                  colSpan: 2,
                },
              ],
              ["", ""],
              ["", ""],
              [
                {
                  content: "Le montant En Chiffres : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: amountReceived,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],
              [
                {
                  content: "Le montant En lettres : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.$lettre.NumberToLetter(this.editedItem.montant),

                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              ],
              [
                {
                  content: "Mode d'encaissement : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.editedItem.type_paye,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],

              [
                {
                  content: "Au profit de : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.editedItem.tier_name,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],

              ["", ""],
              ["", ""],
              [
                "",
                {
                  content: "Service Trésorerie",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              ],
            ];

            // AutoTable settings
            const tableStyles = {
              margin: { top: 20, left: 10 },
              theme: "plain",
              styles: {
                valign: "middle",
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 0,
              },
              tableWidth: "160",
            };
            // Add the AutoTable to the document
            doc.autoTable({
              startY: 10,
              body: tableData,
              columnStyles: {
                0: { halign: "left", cellWidth: "auto" },
              },
              ...tableStyles,
            });
            const tableData2 = [
              [
                {
                  content: "Réf. : " + receiptNumber,
                  styles: { fontStyle: "bold", fontSize: 10 },
                },
                {
                  content: formattedPayDate,
                  styles: { fontStyle: "bold", fontSize: 10, halign: "right" },
                },
              ],
              ["", ""],
              ["", ""],
              [
                {
                  content: "TITRE D'ENCAISSEMENT (Confirmation Client)",
                  styles: {
                    fontStyle: "bold",
                    fontSize: 16,
                    halign: "center",
                    cellPadding: { top: 10, bottom: 10 },
                  },
                  colSpan: 2,
                },
              ],
              [
                {
                  content:
                    "                    Nous confirmons par le present titre d'encaissement avoir remis ;  ",
                  styles: { fontStyle: "normal", fontSize: 12 },
                  colSpan: 2,
                },
              ],
              ["", ""],
              ["", ""],
              [
                {
                  content: "Le montant En Chiffres : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: amountReceived,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],
              [
                {
                  content: "Le montant En lettres : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.$lettre.NumberToLetter(this.editedItem.montant),

                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              ],
              [
                {
                  content: "Mode d'encaissement : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.editedItem.type_paye,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],

              [
                {
                  content: "Au profit de : ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                {
                  content: this.editedItem.tier_name,
                  styles: { fontStyle: "bold", fontSize: 12, halign: "left" },
                },
              ],

              ["", ""],
              ["", ""],
              [
                "",
                {
                  content: "Pour le Client",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              ],
            ];
            doc.autoTable({
              startY: 160,
              body: tableData2,
              columnStyles: {
                0: { halign: "left", cellWidth: "auto" },
              },
              ...tableStyles,
            });

            doc.setLineDash([2, 2]); // Adjust values to change the pattern

            // Set line width and color
            doc.setLineWidth(0.5);
            doc.setDrawColor(0); // Black color

            // Calculate the middle of the page
            //let middleX = doc.internal.pageSize.getWidth() / 2;
            let middleY = doc.internal.pageSize.getHeight() / 2;

            // Draw a dashed line from left to right in the middle of the page
            doc.line(
              10,
              middleY,
              doc.internal.pageSize.getWidth() - 10,
              middleY
            );

            //============================================================

            // Output the PDF in a new window
            doc.output("pdfobjectnewwindow");
          })
          .catch((err) => {
            console.error(err); // Log any errors during QR code generation
          });
      }
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = ["code_tier", "nom"];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    customFilter2(item, queryText) {
      let textlist = [];
      let l = ["nodp", "nofact", "mont_ttc", "fact_date", "dp_date"];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async insert_dps() {
      let b = [];
      let m = this.editedItem.montant;
      this.selected_dps.forEach((element) => {
        let i = this.dps_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          b.push({
            paye_id: this.editedItem.id,
            dp_id: element,
            montant:
              this.dps_list[i].ttc_reste <= m ? this.dps_list[i].ttc_reste : m,
            create_uid: this.$store.state.me.id,
            write_uid: this.$store.state.me.id,
          });
          m = m - this.dps_list[i].ttc_reste;
        }
      });

      return b;
    },
    change_mode() {
      this.espece = this.editedItem.modepaiement == "4" ? 1 : 2;
      if (this.editedItem.modepaiement == "4") {
        this.editedItem.agence_id = null;
        this.editedItem.bank_id = null;
        this.editedItem.nocheque = null;
        this.editedItem.date_cheque = null;
        this.editedItem.facture = this.paye_bl ? "0" : "1";
      }
      if (
        this.tiers_agences_list.length == 1 &&
        this.editedItem.modepaiement == "3"
      ) {
        this.editedItem.agence_id = this.tiers_agences_list[0].id;
        this.agence_change();
      }
      if (this.agences.length == 1 && this.editedItem.modepaiement == "1")
        this.editedItem.agence_id = this.agences[0].id;
      this.mp = this.editedItem.modepaiement;
    },
    dp_change() {
      if (this.paye_type == 1) {
        this.editedItem.modepaiement = null;
        this.editedItem.montant = this.montant_max;
        if (this.selected_dps.length > 0) {
          let l = this.selected_dps[this.selected_dps.length - 1];
          let i = this.dps_list.findIndex((elm) => elm.id == l);
          if (i >= 0) {
            this.editedItem.modepaiement = this.dps_list[i].modepaiement;
            this.change_mode();
          }
        }
      }
    },
    async tier_change(item, l) {
      let mp = "0";
      let list = [];
      if (l) list = l;
      this.editedItem.tier_id = item;
      if (this.document)
        if (this.document.modepaiement) mp = this.document.modepaiement;
      if (mp == "0") {
        this.espece = 0;
        if (this.editedItem.id < 0) this.editedItem.modepaiement = null;
        this.mp = null;
      }

      let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
      if (this.paye_type == 3) {
        if (mp == "0" && i >= 0) {
          if (this.editedItem.id < 0)
            this.editedItem.modepaiement = list[i].modepaiement;
          this.editedItem.situation_ini = list[i].situation_ini;
          this.editedItem.situation_ini_bl = list[i].situation_ini_bl;
          if (this.editedItem.modepaiement) {
            this.mp = this.editedItem.modepaiement;
            this.change_mode();
          }
        }
      }
      //  if (this.document) this.$refs.chq.focus();
      //  else this.$refs.mnt.focus();
      if (i >= 0) {
        if (myBL == "b") this.old_situation = list[i].situation_gl;
        else if (this.editedItem.facture == "0")
          this.old_situation = list[i].situation_enl;
        else this.old_situation = list[i].situation_fact;
      }
      if (this.paye_type == 3) {
        let v = {
          where: {
            AND: [
              {
                column: "CMD_TYPE",
                operator: "IN",
                value: this.paye_type == 1 ? [2, 4] : [1, 3],
              },
              { column: "STATUT_ID", value: 7 },
              { column: "TIER_ID", value: this.editedItem.tier_id },
            ],
          },
        };
        this.cmd_load = true;
        let r = await this.requette(ACTUAL_CMDS, v, "no-cache");
        this.cmd_load = false;
        if (r) this.cmds_list = r.allcmds;
      }
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async unlock() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller " +
            (this.paye_type == 3 ? "cet encaissement?" : "ce paiement?")
        )
      ) {
        this.editedItem.statut_id = 16;
        let v = {
          paye: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            date_opr: this.$store.state.today,
            write_uid: this.$store.state.me.id,
          },
        };

        await this.maj(UPDATE_PAYE, v, true);
        this.$emit("change", this.editedItem);
      }
    },
    async valid_paye(statut_id) {
      this.tovalid = this.editedItem.statut_id == 16 && statut_id == 17;
      this.toaccept =
        (this.editedItem.statut_id == 17 && statut_id == 17) || statut_id == 18;
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir " +
              (this.editedItem.statut_id == 16 && statut_id == 17
                ? "valider"
                : this.editedItem.statut_id == 17 && statut_id == 17
                ? "accepter"
                : "rejeter") +
              (this.paye_type == 3 ? " cet encaissement?" : " ce paiement?")
          )
        ) {
          this.save(statut_id);
          this.close();
        }
      }
    },

    close() {
      if (this.document.id) {
        this.$router.push({
          name: this.document.name,
          params: {
            dp_id: this.document.id,
            filtre: this.document.nodp,
          },
        });
      } else this.$emit("close", this.editedItem);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },

    async save(statut_id) {
      this.tovalid = statut_id == 17;
      if (this.$refs.form.validate()) {
        this.editedItem.statut_id = statut_id;
        this.modify =
          this.editer &&
          (this.editedItem.statut_id == "16" ||
            this.editedItem.countdetails == 0);
        let paye_details = [];
        let dps = [];
        if (this.document.tier_id && this.tovalid) {
          paye_details.push({
            fact_id:
              this.paye_type == 3 && this.editedItem.facture == "1"
                ? this.document.id
                : null,
            mvm_id: this.editedItem.facture == "0" ? this.document.id : null,
            dp_id:
              this.paye_type == 1 && this.editedItem.facture == "1"
                ? this.document.id
                : null,
            montant: parseFloat(
              this.document.mont_ttc <= this.editedItem.montant
                ? this.document.mont_ttc
                : this.editedItem.montant
            ),
          });
        }
        if (this.paye_type == 1 && this.editedItem.facture == "1")
          dps = this.insert_dps();
        let v = {
          input: [
            {
              id: this.editedItem.id > 0 ? this.editedItem.id : null,
              paye_type: this.paye_type,
              paye_ref: this.editedItem.paye_ref,
              paye_date: this.editedItem.paye_date,
              tier_id: this.editedItem.tier_id,
              montant: parseFloat(this.editedItem.montant),
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              modepaiement: this.editedItem.modepaiement,
              bank_id: this.editedItem.bank_id,
              nocheque: this.editedItem.nocheque,
              date_cheque: this.editedItem.date_cheque,
              date_remise: this.editedItem.date_remise,
              date_effet: this.editedItem.date_effet,
              motif: this.editedItem.motif,
              agence_id: this.editedItem.agence_id,
              compte_id: this.editedItem.compte_id,
              facture: this.editedItem.facture,
              taux_avance: this.editedItem.taux_avance,
              cmd_id: this.editedItem.cmd_id,
              paye_details: paye_details.length > 0 ? paye_details : null,
              dps: dps.length > 0 ? dps : null,
            },
          ],
        };
        let r = await this.maj(UPSERT_PAYE, v, true);
        if (r) {
          if (!this.editedItem.id) this.editedItem.id = r.UpsertPaiement;
          if (this.$route.params.document) this.$route.params.document = null;

          this.close();
          this.$emit("change", this.editedItem);
        }
      }
    },
  },
};
</script>
